/*
@contract horizontal-scroll-arrow-button {
  color: Color
  position: Position
  margin: Margin
  hidden {
    display: Display
  }
  hover {
    color: Color
  }
  direction: Left
  box-shadow: BoxShadow
  dimensions: Width
  top: Top
  icon-dimensions: Width
}
*/

.text {
  color: var(--horizontal-scroll-arrow-button-color);
  width: var(--horizontal-scroll-arrow-button-icon-dimensions);
  height: var(--horizontal-scroll-arrow-button-icon-dimensions);
}

.text.disabled {
  color: var(--color-gray-300);
}

.text:hover:not(.disabled) {
  color: var(--horizontal-scroll-arrow-button-hover-color);
}

.button {
  pointer-events: auto;
  display: flex;
  top: var(--horizontal-scroll-arrow-button-top);
  margin: var(--horizontal-scroll-arrow-button-margin);
  background-color: var(--color-white);
  justify-content: center;
  align-items: center;
  width: var(--horizontal-scroll-arrow-button-dimensions);
  height: var(--horizontal-scroll-arrow-button-dimensions);
  border-radius: var(--border-radius-full);
  box-shadow: var(
    --horizontal-scroll-arrow-button-box-shadow,
    var(--shadow-100)
  );
  padding: 3px 7px;
}

.right {
  right: var(--horizontal-scroll-arrow-button-direction, 0);
}

.left {
  left: var(--horizontal-scroll-arrow-button-direction, 0);
}

.disabled {
  visibility: var(--horizontal-scroll-arrow-button-disabled-visibility, hidden);
}
