@layer components {
  .container {
    width: 100%;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    position: var(--horizontal-scroll-container-position);
    padding: 0;
    margin: var(--horizontal-scroll-container-margin);
  }

  .list {
    position: var(--horizontal-scroll-list-position);
    width: 100%;
    list-style: none;
    display: var(--horizontal-scroll-list-display);
    scroll-snap-type: x proximity;
    margin: var(--horizontal-scroll-list-margin);
    padding: var(--horizontal-scroll-list-padding, 0);
    gap: var(--horizontal-scroll-list-gap);
    grid-auto-flow: column;
    overflow-x: var(--horizontal-scroll-list-overflow-x);
    overflow-y: hidden;
    justify-content: flex-start;
    align-items: var(--horizontal-scroll-list-align-items);
    scroll-behavior: var(--horizontal-scroll-list-scroll-behavior);
    height: var(--horizontal-scroll-list-height);
  }

  .noScrollbar {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }

  .noScrollbar::-webkit-scrollbar {
    width: 0;
    background: transparent; /* Chrome/Safari/Webkit */
    display: none;
  }

  .gradients {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
    pointer-events: none;
    position: absolute;
    z-index: var(--z-index-1x);
    width: 100%;
  }

  .arrows {
    align-items: center;
    display: none;
    gap: var(--horizontal-scroll-arrows-gap, var(--space-2x));
    height: var(--horizontal-scroll-arrows-height, 100%);
    justify-content: var(
      --horizontal-scroll-arrows-justify-content,
      space-between
    );
    pointer-events: none;
    position: absolute;
    top: var(--horizontal-scroll-arrows-top, 0);
    width: var(--horizontal-scroll-arrows-width, 100%);
    z-index: var(--z-index-2x);

    &.notMobile {
      @media (--supports-mouse) {
        display: var(--horizontal-scroll-arrows-display, flex);
      }
    }
  }

  .arrows:has(> button[disabled] + button[disabled]) {
    display: none;

    &.notMobile {
      @media (--supports-mouse) {
        display: none;
      }
    }
  }
}
