.gradient {
  width: var(--horizontal-scroll-gradient-width, 50px);
  height: 100%;
  background-color: transparent;
}

.left {
  background: var(
    --horizontal-scroll-gradient-left-background,
    linear-gradient(
      to left,
      transparent 10%,
      var(--horizontal-scroll-gradient-background-color)
    )
  );
}

.right {
  background: var(
    --horizontal-scroll-gradient-right-background,
    linear-gradient(
      to right,
      transparent 10%,
      var(--horizontal-scroll-gradient-background-color)
    )
  );
}

.disabled {
  visibility: hidden;
}
