.leadFormHeader {
  --heading-padding: var(--space-1x) 0 var(--space-1-5x);
  --anchor-font-size: var(--font-size-md);
  padding-bottom: var(--space-4x);
}

.leadFormHeaderText {
  display: inline-block;
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  color: var(--color-black);
  margin-right: var(--space-0-5x);
}

.leadFormHeaderBasic {
  text-align: center;
}

.leadFormHeaderBasicModal {
  text-align: left;
}

.leadFormHeaderBasicModal .leadFormHeaderText {
  margin-bottom: var(--space-1x);
}

.leadFormHeaderTo {
  font-size: var(--font-size-body);
  text-align: left;
}
